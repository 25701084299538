:root {
  //Shadows
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.2);
  --shadow-light: 0 0.3rem 0.7rem rgba(0, 0, 0, 0.2);

  //lines
  --underline: 2px solid var(--grey-light-2);
}

//breakpoints

$x-small: 480px;
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1600px;
