@import "./variables";

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media only screen and (max-width: $large) {
    font-size: 50%;
  }
}

// CUSTOM ANT OVERRIDES

.wrap-label {
  .ant-form-item-label {
    overflow: visible;
    white-space: normal;
  }
  .ant-form-item-required {
    overflow: visible;
  }
}

h1.ant-typography {
  font-size: 3.8rem;
}

h2.ant-typography {
  font-size: 3rem;
}

.ant-statistic-title {
  font-size: 16px;
  font-weight: bold;
}
.ant-statistic-content {
  font-size: 20px;
}

.ant-layout-sider-children {
  min-height: 100%;
  height: auto;
}

// modals y messages
.ant-message-custom-content.ant-message-error,
.ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 1.6rem;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 1.8rem;
}

.ant-message-custom-content.ant-message-error ul > li {
  text-align: left;
}

.ant-page-header-back {
  font-size: 22px;
}

.ant-page-header-heading-title {
  font-size: 2.5rem;
}

.ant-tag {
  font-size: 14px;
}

span.ant-typography {
  font-size: 16px;
}

.modal-big-title {
  .ant-modal-body {
    .ant-modal-confirm-title {
      font-size: 2.4rem;
    }
    .ant-modal-confirm-content {
      font-size: 1.8rem;
    }
  }
}
.react-pdf__message.react-pdf__message--no-data {
  display: none;
}

// utility classes

.hide {
  display: none;
}

.show {
  display: block;
}

.show-toggle {
  display: revert;
}

.show-td {
  display: table-cell;
}
.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}
// prueba en caso de querer cambiar el tamaño de letra de todos los botones
// .ant-btn.ant-btn-primary {
//   font-size: 1.6rem;
//   height: auto;
// }

.center-element {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: "1em";
}
